import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';
import GalleryGrid from './components/GalleryGrid';



class Gallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentTab: 0
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  onTabClick = (tab) => {
    this.setState({
      currentTab: tab
    })
  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="gallery" className="inner-wrapper">
          <h1>{this.props.t('Gallery')}</h1>


            <TriangleAnimated right />
            <TriangleAnimated left />
            <TriangleAnimated right bottom />
            <TriangleAnimated left bottom />


          <div className="container">
            <div className="body">
              {this.props.t('Erlebe das alba Festival nochmal in Bildern.')}
            </div>
          </div>


          <div className="container">
            <div className="headline">
              <h2>2024</h2>
            </div>
            <ul className="nav nav-tabs" id="gallery-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button onClick={() => this.onTabClick(0)} className="nav-link active" id="gallery-sa-tab" data-bs-toggle="tab" data-bs-target="#gallery-sa-tab-pane" type="button" role="tab" aria-controls="gallery-sa-tab-pane" aria-selected="true">People</button>
              </li>
              <li className="nav-item" role="presentation">
                <button onClick={() => this.onTabClick(1)} className="nav-link" id="gallery-so-tab" data-bs-toggle="tab" data-bs-target="#gallery-so-tab-pane" type="button" role="tab" aria-controls="gallery-so-tab-pane" aria-selected="true">Photowall</button>
              </li>
              <li className="nav-item" role="presentation">
                <button onClick={() => this.onTabClick(2)} className="nav-link" id="cube-sa-tab" data-bs-toggle="tab" data-bs-target="#cube-sa-tab-pane" type="button" role="tab" aria-controls="cube-sa-tab-pane" aria-selected="true">Artist</button>
              </li>

            </ul>
            <div className="tab-content" id="gallery-tab-content">
              <div className="tab-pane fade show active" id="gallery-sa-tab-pane" role="tabpanel" aria-labelledby="gallery-sa-tab" tabindex="0">

                {this.state.currentTab == 0 &&
                  <GalleryGrid uuid={'e8c639d6-b706-4494-96b7-8a5fc5dc78dd'} />
                }

              </div>
              <div className="tab-pane fade" id="gallery-so-tab-pane" role="tabpanel" aria-labelledby="gallery-so-tab" tabindex="0">


                  {this.state.currentTab == 1 &&
                    <GalleryGrid uuid={'4a3cd6fc-80bd-4997-ba26-9d246aad29f4'} />
                  }

              </div>
              <div className="tab-pane fade" id="cube-sa-tab-pane" role="tabpanel" aria-labelledby="cube-sa-tab" tabindex="0">


                  {this.state.currentTab == 2 &&
                    <GalleryGrid uuid={'9b11e8da-0028-49fa-958c-4bdeed91f383'} />
                  }

              </div>
             
            </div>
          </div>




          <Ticket />

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Gallery);
