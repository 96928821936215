import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import React, { lazy, Suspense, useEffect } from 'react'
import { Link } from "react-router-dom";

import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';

import Home from './pages/home/Home';
import Home2023 from './pages/home/Home2023';
import HomeRefund from './pages/home/HomeRefund';
import Lineup from './pages/lineup/Lineup';
import Artist from './pages/artist/Artist';
import Tickets from './pages/tickets/Tickets';
import Infos from './pages/infos/Infos';
import Festival from './pages/festival/Festival';
import Contact from './pages/contact/Contact';
import History from './pages/history/History';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint';
import Covid from './pages/covid/Covid';
import Terms from './pages/Terms';
import Lounges from './pages/lounges/Lounges';
import Gallery from './pages/gallery/Gallery';

import ThankYou from './pages/home/ThankYou';


import PlatinImage from "./assets/images/platin.png";
import GoldImage from "./assets/images/gold.png";
import SilverImage from "./assets/images/silber.png";
import i18n from './i18n';

import ReactGA from 'react-ga';
import CookieFirst from './blocks/cookie/CookieFirst';
import PWAPrompt from 'react-ios-pwa-prompt'
import Player from './modules/player/Player';
import { Modal } from 'bootstrap';
import * as qs from 'query-string';
import { withTranslation, Trans } from 'react-i18next';


function App(props) {


  var defaultPageTitle = 'alba Festival';
  const parsed = qs.parse(window.location.search);
  ////console.log(parsed);




  return (

    <Suspense fallback={<div></div>}>
      <div className="App">

        <Header useSuspense={true} />

        <section id="content-wrapper">
          <Switch history={history}>
            {/*<Route path='*' exact render={(props) => <ThankYou {...props} pageTitle={defaultPageTitle + ' - ' + 'Home'}/>} />  application={this}}  />*/}
          <Route path='/' exact render={(props) => <Home {...props} pageTitle={defaultPageTitle + ' - ' + 'Home'}/>} />  application={this}}  />
            <Route path='/home' exact render={(props) => <Home {...props} pageTitle={defaultPageTitle + ' - ' + 'Home'}/>} />  application={this} />


            <Route path='/lineup' exact render={(props) => <Lineup {...props} pageTitle={defaultPageTitle + ' - ' + 'Line-up'}/>} />  application={this} />
            <Route path='/artist/:id' exact render={(props) => <Artist {...props} pageTitle={defaultPageTitle + ' - ' + 'Artist'}/>} /> application={this} />
            <Route path='/tickets' exact render={(props) => <Tickets {...props} pageTitle={defaultPageTitle + ' - ' + 'Tickets'}/>} /> application={this} />
            <Route path='/infos' exact render={(props) => <Infos {...props} pageTitle={defaultPageTitle + ' - ' + 'Infos'}/>} /> application={this} />
            <Route path='/festival' exact render={(props) => <Festival {...props} pageTitle={defaultPageTitle + ' - ' + 'Festival'}/>} /> application={this} />
            <Route path='/contact' exact render={(props) => <Contact {...props} pageTitle={defaultPageTitle + ' - ' + 'Contact'}/>} /> application={this} />
            <Route path='/history' exact render={(props) => <History {...props} pageTitle={defaultPageTitle + ' - ' + 'History'}/>} /> application={this} />
          <Route path='/gallery' exact render={(props) => <Gallery {...props} pageTitle={defaultPageTitle + ' - ' + 'Gallery'}/>} /> application={this} />
            <Route path='/privacy' exact render={(props) => <Privacy {...props} pageTitle={defaultPageTitle + ' - ' + 'Privacy'}/>} /> application={this} />


            <Route path='/lounges' exact render={(props) => <Lounges {...props} pageTitle={defaultPageTitle + ' - ' + 'Lounges'}/>} /> application={this} />

            <Route path='/covid' exact render={(props) => <Covid {...props} pageTitle={defaultPageTitle + ' - ' + 'Covid'}/>} /> application={this} />
          

            <Route path='/agb' exact render={(props) => <Terms {...props} pageTitle={defaultPageTitle + ' - ' + 'Terms'}/>} /> application={this} />
            <Route path='/terms' exact render={(props) => <Terms {...props} pageTitle={defaultPageTitle + ' - ' + 'Terms'}/>} /> application={this} />
              <Route path='/imprint' exact render={(props) => <Imprint {...props} pageTitle={defaultPageTitle + ' - ' + 'Imprint'}/>} /> application={this} />
          </Switch>
        </section>

        <Footer useSuspense={false} />

          <div class="modal fade" id="partner-modal" tabindex="-1" aria-labelledby="partner-modal-label" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="partner-modal-label">{props.t('Partner & Sponsoren')}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <h3>{props.t('Sponsoring')}</h3>
                  <div class="body">
                    {props.t('Festivals sind eine gute Möglichkeit, junge Menschen verschiedener Herkunft zu erreichen. Sänger hautnah zu erleben, ist für viele von ihnen eines der grössten Highlights des Jahres. Entsprechend lukrativ sind auch die Werbemöglichkeiten am alba Festival')} {' '}
                    {props.t('Unsere Zielgruppe gilt als ausgesprochen konsumfreudig und besonders aufgeschlossen. Von diesem Event und Folgemassnahmen wie etwa YouTube-Videos können Sie sich einen respektablen Impact erhoffen. Hierfür stehen noch diverse Sponsoring-Formen offen.')}
                  </div>

                  <hr />

                  <h3>{props.t('Sponsoring-Kategorien')}</h3>
                  <div class="body container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <h4>{props.t('Presenting Partner')}</h4>
                        <h5>{props.t('Platin')}</h5>
                        <img src={PlatinImage} className="img-fluid" />
                        <p>
                          {props.t('Sie werden als wichtigster Werbepartner einprägsam auf allen vorhandenen Werbeflächen dargestellt und mit Sicherheit einen bleibenden Eindruck hinterlassen.')}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>{props.t('Hauptsponsor')}</h4>
                        <h5>{props.t('Gold')}</h5>
                        <img src={GoldImage} className="img-fluid" />
                        <p>
                          {props.t('Als Gold Hauptsponsor sichern Sie sich ein wertbewirksames Grundpaket an Werbemöglichkeiten.')}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>{props.t('Co-Sposor')}</h4>
                        <h5>{props.t('Silber')}</h5>
                        <img src={SilverImage} className="img-fluid" />
                        <p>
                          {props.t('Das gibt Ihnen die Möglichkeit, Ihr Unternehmen effektiv, aber kostengünstig zu präsentieren.')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="contact">
                    <p>{props.t('Kontaktieren Sie uns für mehr Informationen')}: {' '}<a href="mailto: info@alba-festival.ch">info@alba-festival.ch</a></p>
                  </div>

                </div>
              </div>
            </div>
          </div>







        {/* Modal
        <button type="button" className="btn btn-primary hide" style={{display: 'none'}} id="alertModalTrigger" data-bs-toggle="modal" data-bs-target="#alertModal">
          Launch demo modal
        </button>
        <div className="modal fade" id="alertModal" tabIndex="-1" aria-labelledby="alertModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="alertModalLabel">Wichtige Mitteilung</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body text-start">



              <h3>Liebe Freunde des Alba Festivals</h3>
              Zu unserem grossen Bedauern und völlig überraschend hat Regierungsratspräsidentin Jacqueline Fehr (SP) dem Alba Festival mittels einer Präsidialverfügung die im Juli erteilte Bewilligung weniger als 48 Stunden vor dem Event entzogen.<br /><br />
              Ein Entscheid, den wir vor dem Zürcher Verwaltungsgericht superprovisorisch angefochten haben, den aber das Verwaltungsgericht heute nach summarischer Prüfung gestützt hat.<br />
              Es tut uns daher sehr leid, Euch mitteilen zu müssen, dass das diesjährige Alba Festival aufgrund dieser behördlichen Anordnung ausfällt. <br /><br />
              Alle 250 Personen, die am Festival mitgearbeitet haben, sind sehr enttäuscht.<br />
              Enttäuscht, weil wir viel Herzblut in etwas gesteckt haben, das nun nicht stattfinden kann.<br />
              Enttäuscht aber auch, weil die Begründung für den Bewilligungsentzug massgeblich auf unsere Herkunft abstützt.<br />
              Das kannn und darf nicht sein.<br /><br />
              Die Info zu den gekauften Tickets folgt. Vielen Dank für Euer Verständnis.<br /><br />
              Herzliche Grüsse<br />
              <strong>Euer Alba Festival Team</strong>

                <hr />



                <h3>Dear friends of the alba Festival</h3>
                To our great regret and complete surprise, the president of the Zurich Cantonal Government, Jacqueline Fehr (SP), has withdrawn the permit granted to the alba Festival in July less than 48 hours before the event by means of a presidential decree.<br /><br />
                A decision that we have challenged superprovisorily before the Zurich Administrative Court, but which the Administrative Court has supported today after summary examination.<br />
                We are therefore very sorry to inform you that this year's alba Festival has to be cancelled due to this administrative order. <br /><br />
                All 250 people who worked on the festival are very disappointed.<br />
                Disappointed because we put a lot of heart and soul into something that now cannot take place.<br />
                But also disappointed because the reason for the withdrawal of the permit is mainly based on our origin.<br />
                This cannot and must not be.<br /><br />
                The information about the purchased tickets will follow. Thank you for your understanding.<br /><br />
                Best regards<br />
                <strong>Your alba Festival Team</strong>


              </div>

            </div>
          </div>
        </div>*/}


        {/* Modal
        <div className="modal fade" id="covidModal" tabIndex="-1" aria-labelledby="covidModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="covidModalLabel">Covid Infos</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body text-start">

                  <Covid />

              </div>


            </div>
          </div>
        </div>*/}



      </div>

      {!parsed.isPwa && false &&
        <>
          <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="X" copyShareButtonLabel permanentlyHideOnDismiss={false} debug={false} copyTitle={'alba Festival - App'} copyBody={'Installiere unsere App und erhalte die letzten News und Updates.'} copyShareButtonLabel={'Klicken Sie auf „Teilen“ in der Menüleiste'} copyAddHomeButtonLabel={'und anschliessend auf „Zum Home-Bildschirm“.'} />
        </>
      }

      <CookieFirst />

      <Player />


    </Suspense>
  );
}

export default withTranslation()(App);
