import React, { useState } from "react";
import ReactDOM from "react-dom";
import { MdClose, MdMenu } from "react-icons/md";

import { Link } from 'react-router-dom';

import { useSpring, useTransition, animated, config } from '@react-spring/web';
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import Triangle from "../../blocks/triangle/Triangle";
import "./assets/css/index.css";
import Logo from "../../assets/images/alba-logo.svg";

import PolygonLeft from "./assets/images/menuLeftPolygon.svg";
import PolygonRight from "./assets/images/polygon-web-1.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import { withTranslation, Trans } from 'react-i18next';


const styleToggleButton = {
  fontSize: "3rem",
  color: "rgb(36,36,36)",
  padding: 0,
  border: "none",
  background: "none"
};

const FullscreenMenu = (props) => {


  const [isOpen, setIsOpen] = useState(false);


  const openButton = useTransition(isOpen, {
    from: {
      opacity: 1,
      color: "#fff",
      position: "relatative",
      transform: "scale(1)"
    },
    enter: {
      opacity: 1,
      transform: "scale(1)"
    },
    leave: { opacity: 1, transform: "scale(1)" },
    config: config.default
  });
  const fullscreenMenu = useTransition(isOpen, {
    from: {
      opacity: 0,
      transform: "scale(1)",
      position: "relative",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
      position: "absolute",
      maxHeight: "90vh",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    leave: {
      opacity: 0,
      height: "0px",
      transform: "scale(1)",
      position: "relative",
      overflow: 'hidden'
    },
    config: config.default
  });

  const styles = useSpring({
      loop: true,
      from: {y: "-0px" },
      to: { y: "5px" },
      config: config.default
    })


  const toggleMenu = () => {
    document.body.classList.toggle('menu-open');
      setIsOpen(!isOpen);
    };


    const PolygonLeftAnimateProps = useSpring({
      transform: isOpen ? "translateX(0px)" : "translateX(-250px)",
      from: { transform: "translateX(-250px)" },
      config: config.default
    });

    const PolygonRightAnimateProps = useSpring({
      transform: isOpen ? "translateX(0)" : "translateX(250px)",
      from: { transform: "translateX(250px)" },
      config: config.default
    });


    return (
    <div
      className={isOpen ? "fullscreen-menu open" : "fullscreen-menu closed"}
      style={{
        position: isOpen ? "fixed" : "relative",
        left: "0px",
        top: "0px",
        right: "0px",
        bottom: "0px",
      }}
    >


    <div style={{ position: "relative", zIndex: 20 }}>
        {openButton((style, item, key, props) => (
          !item ? (
            <animated.div key={key} style={style}>
              <button className="open-btn" style={styleToggleButton} onClick={toggleMenu}>
                <FontAwesomeIcon icon={AllLightIcons.faAlignLeft} /> <Trans>Menu</Trans>
              </button>
            </animated.div>
          ) : (
            <animated.div key={key} style={style}>
              <button className="close-btn" style={styleToggleButton} onClick={toggleMenu}>
                <FontAwesomeIcon icon={AllLightIcons.faTimesCircle} />
              </button>
            </animated.div>
          )
        ))}
      </div>




      <div>
        {fullscreenMenu((style, item, key) => (
            item && (
              <animated.div key={key} style={style}>

                <div className="logo-bar">
                  <Link to="/" onClick={() => { toggleMenu() }}>
                    <img src={Logo} />
                  </Link>
                </div>

                <div className="year-bar">2024</div>



                <div className="menu-bar container">
                  <div className="row">
                    <div className="col-md-3 col-sm-4 col-ticket">
                      <Link to="/tickets" onClick={() => { toggleMenu() }}>


                        <animated.button
                          style={{

                          backgroundColor: 'transparent',
                          borderRadius: 0,
                          border: 'none',
                          padding: '0px',
                          color: '#fff',
                          ...styles,
                        }}>


                        <div className="ticket-icon">
                          <FontAwesomeIcon icon={AllLightIcons.faTicketAlt} />
                        </div>
                        {props.t('Tickets')}
                        <div className="arrow-icon">


                            <FontAwesomeIcon icon={AllLightIcons.faLongArrowUp} />



                        </div>
                        </animated.button>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-8">

                      <ul
                        className="desktop-menu d-none d-md-block"
                        style={{
                          height: "100%",
                          textAlign: 'left',
                          fontSize: "2vw",
                          padding: 0,
                          margin: 0,
                          listStyle: "none",
                          overflow: "hidden",
                        }}
                      >


                      <li>
                        <Link to="/lineup" onClick={() => { toggleMenu() }}>
                          {props.t('Line-Up')}
                        </Link>
                      </li>

                        <li>
                          <Link to="/festival" onClick={() => { toggleMenu() }}>
                            {props.t('Festival')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/gallery" onClick={() => { toggleMenu() }}>
                            {props.t('Gallery')}
                          </Link>
                        </li>
                        {/**
                         * <li>
                          <Link to="/infos" onClick={() => { toggleMenu() }}>
                            {props.t('Infos')}
                          </Link>
                        </li>
                         * 
                         */}
                        <li>
                          <Link to="/history" onClick={() => { toggleMenu() }}>
                            {props.t('History')}
                          </Link>
                        </li>
                        {/*<li>
                          <Link to="/gallery" onClick={() => { toggleMenu() }}>
                            {props.t('Gallery')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/lounges" onClick={() => { toggleMenu() }}>
                            {props.t('Lounges')}
                          </Link>
                        </li>*/}

                      </ul>



                      <ul
                        className="mobile-menu d-lg-none d-md-none d-sm-flex d-flex"
                        style={{
                          height: "100%",
                          textAlign: 'left',
                          fontSize: "2vw",
                          padding: 0,
                          margin: 0,
                          listStyle: "none",
                          overflow: "hidden",
                        }}
                      >

                        <li>
                          <Link to="/lineup" onClick={() => { toggleMenu() }}>
                            {props.t('Line-Up')}
                          </Link>
                        </li>
                        {/*<li>
                          <Link to="/lounges" onClick={() => { toggleMenu() }}>
                            {props.t('Lounges')}
                          </Link>
                        </li>*/}

                        <li>
                          <Link to="/festival" onClick={() => { toggleMenu() }}>
                            {props.t('Festival')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/infos" onClick={() => { toggleMenu() }}>
                            {props.t('Infos')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/history" onClick={() => { toggleMenu() }}>
                            {props.t('History')}
                          </Link>
                        </li>
                      {/*  <li>
                          <Link to="/gallery" onClick={() => { toggleMenu() }}>
                            {props.t('Gallery')}
                          </Link>
                        </li>*/}
                        <li>
                          <Link to="/contact" onClick={() => { toggleMenu() }}>
                            {props.t('Kontakt')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/terms" onClick={() => { toggleMenu() }}>
                            {props.t('AGB')}
                          </Link>
                        </li>

                      </ul>


                    </div>
                    <div className="col-md-5 d-none d-md-flex">


                        <ul
                          style={{
                            height: "100%",
                            textAlign: 'left',
                            fontSize: "2vw",
                            padding: 0,
                            margin: 0,
                            listStyle: "none",
                            overflow: "hidden",
                            width: '100%'
                          }}
                        >

                          <li>
                            <Link to="/contact" onClick={() => { toggleMenu() }}>
                              {props.t('Kontakt')}
                            </Link>
                          </li>
                        </ul>


                      <div class="info-grid container-fluid">
                        <div className="mail">
                          info@alba-festival.ch
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="social-bar">
                              <h2 style={{color: 'white'}}>{props.t('Follow Us')}</h2>
                              <ul
                                style={{
                                  height: "100%",
                                  textAlign: 'center',
                                  fontSize: "1vw",
                                  padding: 0,
                                  margin: 0,
                                  listStyle: "none",
                                  display: "flex",
                                  justifyContent: "center"
                                }}
                              >
                                <li>
                                  <a href="https://www.facebook.com/albafestival/" target="_blank">
                                    <FontAwesomeIcon icon={AllBrandIcons.faFacebook} />
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/albafestival/?hl=de" target="_blank">
                                    <FontAwesomeIcon icon={AllBrandIcons.faInstagram} />
                                  </a>
                                </li>



                                <li>
                                  <a href="https://www.tiktok.com/@albafestival" target="_blank">
                                    <FontAwesomeIcon icon={AllBrandIcons.faTiktok} />
                                  </a>
                                </li>

                              </ul>
                            </div>



                          </div>
                          <div className="col-sm-8">
                            <div className="terms-bar">
                              <ul
                                style={{
                                  textAlign: 'left',
                                  padding: 0,
                                  margin: 0,
                                  listStyle: "none",
                                  overflow: "hidden",
                                  justifyContent: "center"
                                }}
                              >
                              <li>
                                <Link to="/privacy" onClick={() => { toggleMenu() }}>
                                  {props.t('Datenschutz')}
                                </Link>
                              </li>
                              <li>
                                <Link to="/imprint" onClick={() => { toggleMenu() }}>
                                  {props.t('Impressum')}
                                </Link>
                              </li>
                              <li>
                                <Link to="/terms" onClick={() => { toggleMenu() }}>
                                  {props.t('AGB')}
                                </Link>
                              </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div>
                  </div>
                </div>



                <div className="animatesTriangleLeft">
                  <animated.div style={(isOpen) ? PolygonLeftAnimateProps : null}>
                    <img src={PolygonLeft} />
                  </animated.div>
                </div>

                <div className="animatesTriangleRight">
                  <animated.div style={(isOpen) ? PolygonRightAnimateProps : null}>
                    <img src={PolygonRight} />
                  </animated.div>
                </div>


              </animated.div>
            )
        ))}
      </div>



  </div>
  )




}


export default withTranslation()(FullscreenMenu);
